/* You can add global styles to this file, and also import other style files */
@use "node_modules/@ngxpert/hot-toast/src/styles/styles.scss";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import 'animate.css';
@import "~ng-zorro-antd/ng-zorro-antd.compact.css";

// .bg-primary {
//   background: #097AAE !important;
// }

// .priamry {
//   background: #097AAE !important;
// }

// .bg-secondary {
//   background: #052E41 !important;
// }

// .secondary {
//   background: #052E41 !important;
// }

.btn-primary {
  background: #097AAE !important;

  &:hover {
    background: #0b8bc7 !important;
  }
}

// .btn-secondary {
//   background: #052E41 !important;
// }

// a {

//   &:hover {
//     color: #097AAE !important;

//   }
// }

// .navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true] {
//   color: #097AAE !important;
// }

/* This changes all the animations globally */
:root {
  --animate-duration: 500ms;
  --animate-delay: 0.5s;
}

.ck-content {
  font-family: 'Lato';
  line-height: 1.6;
  word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
  min-width: 100vh;
  max-width: 100%;
}



// Swiper
.coverflowslide .slide {
  position: relative;
  transition: transform 0.3s;
}

.coverflowslide .slick-list {
  overflow: hidden !important;
}

.coverflowslide .slick-center {
  transform: scale(1.2); // Adjust the scale factor for the "Coverflow" effect
  z-index: 1;
}

.coverflowslide .carousel {
  width: 100%;
  max-width: 600px; // Adjust the carousel width as needed
  margin: 0 auto;
}

//mat expansion pannel
.mat-expansion-panel-body {
  padding: 0px !important;
}

.space .slick-slide {
  margin: 10px;
}

[data-bs-theme="dark"] {
  .fc {
    .fc-multimonth-daygrid {
      background: #212529 !important;
    }

    .fc-day-disabled {
      background: #282b2e !important;
    }

    .fc-multimonth {
      border-color: #32383e !important;
    }
  }
}

.ant-table-pagination.ant-pagination {
  margin: 8px 0px 1px;
}

//all talble data must be vertically aligned

tr {
  vertical-align: middle;

  &:nth-child(even) {
    background-color: #f8f8f8 !important;
  }

  &:hover {
    background-color: #f3f3f3 !important;
    transition: all 0.25s;
  }
}

th {
  border-top: 2px solid #ddd !important;
  background-color: #f8f8f8 !important;
}


.wrapperr {
  padding: 1rem 1rem 1rem 1rem;
}


#landingnft {
  .slick-dots {
    bottom: -36px;
    z-index: 1;

    .slick-active {
      button {
        background-color: #3577f1 !important;
      }
    }
  }

  .slick-dots li {
    height: 7px !important;
  }

  .slick-dots li button {
    width: 22px !important;
    height: 5px !important;
    border: none;
    background-color: rgba(109, 98, 253, 0.2) !important;
    padding: 0px;
    margin: 0px;
    transition: all 0.3s;
    border-radius: 50px;

    &::before {
      display: none;
    }
  }
}

.border-rad {
  border: 1px solid #ebebeb !important;
  border-radius: 5px !important;
  box-shadow: 3px 3px 11px 0px #8b8b8b57 !important;
}

.bottom {
  margin-bottom: 0.5rem;
}

.left-pad {
  padding-left: 4rem;
}

.preview-Header {
  padding: 0.5rem;
  background: aliceblue;
  border: 1px solid #ededed;
  border-radius: 5px 5px 0px 0px;
  margin-bottom: 0;
}

.preview-Content {
  padding: 0.5rem;
  margin-bottom: 0;
  border: 1px solid #ededed;
  border-radius: 0px 0px 5px 5px;
  border-top: 0px;
}

.img {
  height: 7rem;
  width: 179px;
  object-fit: cover;
  transition: transform 0.15s ease-in-out;
  border-radius: 5px;

  &:hover {
    transform: scale(1.1);
    z-index: 7
  }
}


// NZ-Styles
[nz-button].primary {
  margin-right: 8px;
  border-color: #287baf;
  color: #287baf;
  &:hover {
    border-color: #287baf;
    color: #f7f7f7;
    background-color: #287baf;
  }
}